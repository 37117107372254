import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import {
  laptopSize,
  desktopSize,
  mobileSize,
  salDuration,
  salTimingFunction,
} from '../../../util/variables';
import theme from '../../../util/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AnimatedLink, AnimatedDiv } from '../../ui';
import logoImg from '../../../content/img/foreign/logo_cierre.png';
import AOS from 'aos';
import { useEffect } from 'react';
import './bootstrap.css';
import 'aos/dist/aos.css';
import './styles.css';
import './sal.css';
require(typeof window !== 'undefined' ? './bootstrap.js' : '../../../util/theme');

const ForeignSegmentPartners: React.FC = () => {
  useEffect(() => AOS.init({ easing: 'ease-in-out', duration: 500, delay: 0 }), []);

  return (
    <div className="partners university">
      <section className="header-meet">
        <div className="container">
          <div className="row content-meet">
            <div className="col">
              <div className="advanced">The Advancement Initiative</div>
            </div>
            <div className="col partners-logo">
              <img src="../../img/base10_partners.svg" />
            </div>
            <div className="col right icon-uni">
              <img src="../../img/icon_uni.svg" />
            </div>
          </div>
        </div>
      </section>
      <section className="header-partners light">
        <div className="container">
          <div className="row intro-partners">
            <div className="col">
              <h1>Meet our partners</h1>
            </div>
            <div className="col">
              <div className="graph-description">
                The Base10 Advancement Initiative’s investors are made up of entirely of HBCUs{' '}
                <span className="light-font">
                  and other institutions whose missions align with the advancement of diverse
                  talent.
                </span>
              </div>
            </div>
            <div className="col right hide-mobile">
              <Link className="back-link" to="/advancement-initiative/">
                Back
              </Link>
            </div>
          </div>
          <div className="row logos-partners">
            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button howard"
                data-bs-toggle="modal"
                data-bs-target="#howard"
              >
                <img src="../../img/partners/uni_howard.png" />
              </button>

              <div
                className="modal fade"
                id="howard"
                tabindex="-1"
                aria-labelledby="howardLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="howardLabel">
                        <img src="../../img/partners/uni_howard.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Founded in 1867, Howard University is a private, research university
                          compromised of 13 schools and colleges.
                        </strong>
                      </p>
                      <p>
                        Students pursue studies in more than 140 areas leading to undergraduate,
                        graduate and professional degrees. Howard produces more on-campus
                        African-American Ph.D. recipients than any other university in the United
                        States.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button className="partner-button ford" data-bs-toggle="modal" data-bs-target="#ford">
                <img src="../../img/partners/uni_ford.png" />
              </button>

              <div
                className="modal fade"
                id="ford"
                tabindex="-1"
                aria-labelledby="fordLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="fordLabel">
                        <img src="../../img/partners/uni_ford.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          FAMU’s academic achievements are what set it apart as a unique learning
                          experience. The U.S. News & World Report lists FAMU as the top public
                          historically black college or university in the nation for 2015.
                        </strong>
                      </p>
                      <p>
                        The University enrolls nearly 10,000 students hailing from across the United
                        States and more than 70 countries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="250"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button hampton"
                data-bs-toggle="modal"
                data-bs-target="#hampton"
              >
                <img src="../../img/partners/uni_hampton.png" />
              </button>

              <div
                className="modal fade"
                id="hampton"
                tabindex="-1"
                aria-labelledby="hamptonLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="hamptonLabel">
                        <img src="../../img/partners/uni_hampton.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Rich in history, steeped in tradition, Hampton University is a dynamic,
                          progressive institution of higher education, providing a broad range of
                          technical, liberal arts, and graduate degree programs.
                        </strong>
                      </p>
                      <p>
                        In addition to being one of the top historically black universities in the
                        world, Hampton University is a tightly-knit community of learners and
                        educators, representing 49 states and 35 territories and nations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col">
              <button
                className="partner-button spelman"
                data-bs-toggle="modal"
                data-bs-target="#spelman"
              >
                <img src="../../img/partners/spelman.svg" />
              </button>
              <div
                className="modal fade"
                id="spelman"
                tabindex="-1"
                aria-labelledby="spelmanLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="spelmanLabel">
                        <img src="../../img/partners/spelman_color.svg" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Founded in 1881, Spelman College is a leading liberal arts college widely
                          recognized as the global leader in the education of women of African
                          descent.
                        </strong>
                      </p>
                      <p>
                        Spelman is the country's leading producer of Black women who complete Ph.D.s
                        in science, technology, engineering and math (STEM).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="350"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button tuskegee"
                data-bs-toggle="modal"
                data-bs-target="#tuskegee"
              >
                <img src="../../img/partners/uni_tuskegee.png" />
              </button>
              <div
                className="modal fade"
                id="tuskegee"
                tabindex="-1"
                aria-labelledby="tuskegeeLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="tuskegeeLabel">
                        <img src="../../img/partners/uni_tuskegee.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Tuskegee University is a private and state-related land-grant institution
                          that serves a racially, ethnically and religiously diverse student body of
                          3,000-plus students.
                        </strong>
                      </p>
                      <p>
                        The institution was founded in 1881 by Booker T. Washington and is one of
                        107 HBCUs nationally and the fourth-ranked HBCU nationally by U.S. News and
                        World Report.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="400"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button macarthur"
                data-bs-toggle="modal"
                data-bs-target="#macarthur"
              >
                <img src="../../img/partners/uni_macarthur.png" />
              </button>
              <div
                className="modal fade"
                id="macarthur"
                tabindex="-1"
                aria-labelledby="macarthurLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="macarthurLabel">
                        <img src="../../img/partners/uni_macarthur.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          The MacArthur Foundation supports creative people, effective institutions,
                          and influential networks building a more just, verdant, and peaceful
                          world.
                        </strong>
                      </p>
                      <p>
                        MacArthur focuses on some of the world’s most pressing social challenges,
                        including global climate solutions, local justice reform in the U.S., and
                        corruption in Africa’s most populous country, Nigeria.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="450"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button plexo"
                data-bs-toggle="modal"
                data-bs-target="#plexo"
              >
                <img src="../../img/partners/uni_plexo.png" />
              </button>
              <div
                className="modal fade"
                id="plexo"
                tabindex="-1"
                aria-labelledby="plexoLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="plexoLabel">
                        <img src="../../img/partners/uni_plexo.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Plexo Capital is a full stack investment firm which invests as a Limited
                          Partner in emerging early stage venture capital fund managers and as a VC
                          in early stage venture backed companies.
                        </strong>
                      </p>
                      <p>
                        Based in San Francisco, CA, the firm was founded in 2017 by Lo Toney, former
                        investing partner with GV.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="500"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button kaiser"
                data-bs-toggle="modal"
                data-bs-target="#kaiser"
              >
                <img src="../../img/partners/uni_kaiser.png" />
              </button>
              <div
                className="modal fade"
                id="kaiser"
                tabindex="-1"
                aria-labelledby="kaiserLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="kaiserLabel">
                        <img src="../../img/partners/uni_kaiser.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          KFF (Kaiser Family Foundation) is a nonprofit organization focusing on
                          national health issues, as well as the U.S. role in global health policy.
                        </strong>
                      </p>
                      <p>
                        KFF develops and runs its own policy analysis, journalism and communications
                        programs, sometimes in partnership with major news organizations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="550"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button className="partner-button uncf" data-bs-toggle="modal" data-bs-target="#uncf">
                <img src="../../img/partners/uni_uncf.png" />
              </button>
              <div
                className="modal fade"
                id="uncf"
                tabindex="-1"
                aria-labelledby="uncfLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="uncfLabel">
                        <img src="../../img/partners/uni_uncf.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          UNCF (United Negro College Fund) is the nation’s largest and most
                          effective minority education organization.
                        </strong>
                      </p>
                      <p>
                        The UNCF supports students’ education and development through scholarships
                        and other programs, supports and strengthens its 37 member colleges and
                        universities, and advocates for the importance of minority education.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="600"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button weinberg"
                data-bs-toggle="modal"
                data-bs-target="#weinberg"
              >
                <img src="../../img/partners/uni_weinberg.png" />
              </button>

              <div
                className="modal fade"
                id="weinberg"
                tabindex="-1"
                aria-labelledby="weinbergLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="weinbergLabel">
                        <img src="../../img/partners/uni_weinberg.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          The Harry and Jeanette Weinberg Foundation, one of the largest private
                          charitable foundations in the United States.
                        </strong>
                      </p>
                      <p>
                        Provides approximately $130 million each year in total grant activity
                        supporting nonprofits in the areas of Housing, Health, Jobs, Education, and
                        Community Services.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="650"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button className="partner-button jpb" data-bs-toggle="modal" data-bs-target="#jpb">
                <img src="../../img/partners/uni_jpb.png" />
              </button>
              <div
                className="modal fade"
                id="jpb"
                tabindex="-1"
                aria-labelledby="jpbLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="jpbLabel">
                        <img src="../../img/partners/uni_jpb.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          The JPB Foundation's mission is to advance opportunity in the United
                          States
                        </strong>{' '}
                        through transformational initiatives that empower those living in poverty,
                        enrich and sustain our environment, and enable pioneering medical research.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="700"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button welfare"
                data-bs-toggle="modal"
                data-bs-target="#welfare"
              >
                <img src="../../img/partners/uni_welfare.png" />
              </button>

              <div
                className="modal fade"
                id="welfare"
                tabindex="-1"
                aria-labelledby="welfareLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="welfareLabel">
                        <img src="../../img/partners/uni_welfare.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          The Public Welfare Foundation supports efforts to advance justice and
                          opportunity for people in need.
                        </strong>
                      </p>
                      <p>
                        These efforts honor the Foundation’s core values of racial equity, economic
                        well-being and fundamental fairness for all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="750"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button silicon"
                data-bs-toggle="modal"
                data-bs-target="#silicon"
              >
                <img src="../../img/partners/uni_silicon.png" />
              </button>
              <div
                className="modal fade"
                id="silicon"
                tabindex="-1"
                aria-labelledby="siliconLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="siliconLabel">
                        <img src="../../img/partners/uni_silicon.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Silicon Valley Community Foundation advances innovative philanthropic
                          solutions to challenging problems.
                        </strong>
                      </p>
                      <p>
                        We engage donors and corporations from Silicon Valley, across the country
                        and around the globe to make our region and world better for all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="800"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button kapor"
                data-bs-toggle="modal"
                data-bs-target="#kapor"
              >
                <img src="../../img/partners/uni_kapor.png" />
              </button>
              <div
                className="modal fade"
                id="kapor"
                tabindex="-1"
                aria-labelledby="kaporLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="kaporLabel">
                        <img src="../../img/partners/uni_kapor.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          The Kapor Foundation aims to enhance diversity and inclusion in the
                          technology and entrepreneurship ecosystem
                        </strong>{' '}
                        through increasing access to tech and STEM education programs, investing in
                        community organizations and gap-closing social ventures, and increasing
                        access to capital among diverse entrepreneurs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="850"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button sorenson"
                data-bs-toggle="modal"
                data-bs-target="#sorenson"
              >
                <img src="../../img/partners/uni_sorenson.png" />
              </button>
              <div
                className="modal fade"
                id="sorenson"
                tabindex="-1"
                aria-labelledby="sorensonLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="sorensonLabel">
                        <img src="../../img/partners/uni_sorenson.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          The Sorenson Impact Foundation is dedicated to providing grants that make
                          a lasting impact on individuals, families, and societies.
                        </strong>
                      </p>
                      <p>
                        Our primary areas of emphasis include social impact, education &
                        entrepreneurial training and healthcare.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="900"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button cambridge"
                data-bs-toggle="modal"
                data-bs-target="#cambridge"
              >
                <img src="../../img/partners/uni_cambridge.png" />
              </button>
              <div
                className="modal fade"
                id="cambridge"
                tabindex="-1"
                aria-labelledby="cambridgeLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="cambridgeLabel">
                        <img src="../../img/partners/uni_cambridge.png" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          With more than 45 years of institutional investing insights, Cambridge
                          Associates
                        </strong>{' '}
                        aims to help pension plans, endowments & foundations, healthcare systems,
                        and private clients implement and manage custom investment portfolios to
                        generate outperformance so they can maximize their impact on the world
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="800"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button JRP"
                data-bs-toggle="modal"
                data-bs-target="#jasper_ridge"
              >
                <img src="../../img/partners/com_JRP.png" width="200px" />
              </button>
              <div
                className="modal fade"
                id="jasper_ridge"
                tabindex="-1"
                aria-labelledby="jasperRidgeLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="jasperRidgeLabel">
                        <img src="../../img/partners/com_JRP.png" width="200px" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          Jasper Ridge Partners provides discretionary investment management
                          services and customized solutions
                        </strong>{' '}
                        to prominent families, foundations and global institutions, with expertise
                        across all major asset classes, including public equity, fixed income, hedge
                        funds, private equity, venture capital, real estate, and natural resources.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-3"
              data-sal="fade"
              data-sal-delay="800"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <button
                className="partner-button first_close"
                data-bs-toggle="modal"
                data-bs-target="#first_close"
              >
                <img src="../../img/partners/first_close.png" width="200px" />
              </button>
              <div
                className="modal fade"
                id="first_close"
                tabindex="-1"
                aria-labelledby="firstCloseLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="firstCloseLabel">
                        <img src="../../img/partners/first_close.png" width="200px" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          First Close Partners backs venture funds owned by underrepresented
                          managers—especially to help them get to a “first close.”
                        </strong>{' '}
                        We invest in venture capital firms owned and operated by URMs who are people
                        of color, women, LGBTQIA+, Indigenous people, people with disabilities, and
                        members of other groups that have been historically underrepresented,
                        especially (but not solely) in the venture capital industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3">
              <button
                className="partner-button mcgregor"
                data-bs-toggle="modal"
                data-bs-target="#mcgregor"
              >
                <img src="../../img/partners/uni_mcgregor.png" width="200px" />
              </button>

              <div
                className="modal fade"
                id="mcgregor"
                tabindex="-1"
                aria-labelledby="mcgregorLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-title" id="mcgregorLabel">
                        <img src="../../img/partners/uni_mcgregor.png" width="200px" />
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>
                        <strong>
                          The McGregor Fund is a private foundation established in 1925 by gifts
                          from Katherine and Tracy McGregor “to relieve the misfortunes and promote
                          the wellbeing of humankind.”
                        </strong>
                      </p>
                      <p>
                        The McGregor Fund has granted over $250M since its founding, with
                        grantmaking prioritizing basic needs, recovery and restoration, and
                        transformational skill building opportunities for teens and adults living in
                        poverty.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="quotes-partners dark">
        <div className="container">
          <div className="row quotes-block">
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="100"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                With the Advancement Initiative, Base10 has built an outstanding mechanism for HBCUs
                to grow our endowments and give students previously inaccessible opportunities
                post-graduation. Howard is thrilled to be partnering with Base10 to continue to
                increase HBCUs’ impact on the black community through wealth creation and improving
                diversity in the tech industry.
              </div>
              <div className="quote-meet-info right frank">
                <div className="name-quote">Frank Bello</div>
                <div className="position-quote">
                  Chief Investment Officer
                  <br />
                  Howard University
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile"></div>
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="150"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                There is incredible alignment among the missions of FAMU, the FAMU Foundation, and
                Base 10 Partners. I am so excited that our Florida A&M University (FAMU) Foundation
                Finance Committee and Foundation Board voted to invest in Base 10 Partners. This
                investment will pay multiplicative dividends for our current students and future
                Rattlers for generations to come as well as society at large (inclusive of
                minorities and diverse entrepreneurial ventures).
              </div>
              <div className="quote-meet-info right shawnta">
                <div className="name-quote">Dr. Shawnta Friday-Stroud </div>
                <div className="position-quote">
                  Vice President, University Advancement & Executive Director
                  <br />
                  Florida A&M University Foundation
                </div>
              </div>
            </div>

            <div className="col-1 hide-mobile"></div>

            <div
              className="col"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                We are excited to see more venture capital funds making commitments to helping grow
                HBCU endowments. The Base10 Advancement Initiative is positioned to help HBCUs help
                students that need it most in an effective, scalable way, and we are proud to be
                backing the success of this fund.
              </div>
              <div className="quote-meet-info right keon">
                <div className="name-quote">Keon Holmes</div>
                <div className="position-quote">
                  Managing Director
                  <br />
                  Cambridge Associates
                </div>
              </div>
            </div>
          </div>
          <div className="row quotes-second">
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="250"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                HBCUs and their endowments have been historically excluded from investments into
                venture capital and technology, and the Advancement Initiative not only grants these
                crucial institutions access, but helps prioritize them in incredibly competitive
                deals. We are super excited about our partnership with Base10.
              </div>
              <div className="quote-meet-info right marcelia">
                <div className="name-quote">Marcelia Freeman</div>
                <div className="position-quote">
                  Board of Directors
                  <br />
                  Florida A&M University
                </div>
              </div>
            </div>

            <div className="col-1 hide-mobile"></div>

            <div
              className="col"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                We are pleased to invest with Base10 which is taking an innovative approach to build
                diversity in the investment management field and in providing opportunities for HBCU
                endowments to invest in private equity opportunities.
              </div>
              <div className="quote-meet-info right susanManske">
                <div className="name-quote">Susan Manske</div>
                <div className="position-quote">
                  Chief Investment Officer
                  <br />
                  The John D. and Catherine T. MacArthur Foundation
                </div>
              </div>
            </div>
            <div className="col-1 hide-mobile"></div>

            <div
              className="col"
              data-sal="fade"
              data-sal-delay="100"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                Base10 has recognized the key role HBCUs play in fostering talented black students
                who go on to become business leaders. Hampton is proud to be supporting the
                Advancement Initiative, which we believe will be instrumental in increasing
                opportunities for these students.
              </div>
              <div className="quote-meet-info right williamHarvey">
                <div className="name-quote">Dr. William R. Harvey</div>
                <div className="position-quote">
                  President
                  <br />
                  Hampton University
                </div>
              </div>
            </div>
          </div>

          <div className="row quotes-third">
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-duration="700"
              data-sal-easing="easeInOutQuad"
            >
              <div className="quote-meet">
                In Silicon Valley, workforce diversity, equity and inclusion are serious structural
                challenges that must be addressed. At Silicon Valley Community Foundation, we
                believe that we build stronger communities by investing in leaders of color and
                their organizations. Base10's unique approach to improving workforce diversity and
                inclusion through its Advancement Initiative will bolster the endowments of HBCUs
                and provide more opportunity to deserving students. That, in turn, will build a more
                diverse workforce over the long term, and give more people equitable opportunities
                to thrive. We are proud to partner with Base10 to help realize that goal.
              </div>
              <div className="quote-meet-info right nicoleTaylor">
                <div className="name-quote">Nicole Taylor</div>
                <div className="position-quote">
                  President and CEO
                  <br />
                  Silicon Valley Community Foundation
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta">
        <div className="container">
          <div className="cta-content">
            <div className="row">
              <div className="col">
                <h1
                  data-sal="slide-right"
                  data-sal-delay="100"
                  data-sal-duration="700"
                  data-sal-easing="easeInOutQuad"
                >
                  Want to join the Advancement Initiative?
                </h1>
                <a href="mailto:advance@base10.vc">
                  <button
                    style={{ height: '48px', fontSize: '20px', padding: '8px 16px' }}
                    data-sal="slide-right"
                    data-sal-delay="400"
                    data-sal-duration="700"
                    data-sal-easing="easeInOutQuad"
                    type="button"
                    className="w-200 btn btn-lg btn-primary"
                  >
                    Get in touch
                  </button>
                </a>
              </div>
              <div className="col hide-mobile"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="back">
        <div className="container">
          <a className="link-footer" href="/advancement-initiative/">
            Back to the Advancement Initiative Home Page
          </a>
        </div>
      </section>
    </div>
  );
};

export { ForeignSegmentPartners };
